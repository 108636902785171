





























































import { Component } from 'vue-property-decorator'
import VueBase from '@/VueBase'
import { Form } from 'element-ui'

@Component({ name: 'ChangePassword' })
export default class ChangePassword extends VueBase {
  submitForm = {
    old_password: '',
    new_password: '',
    checkPass: '',
  }

  private rules = {
    old_password: [{ validator: this.validateOldPass, trigger: 'blur' }],
    new_password: [{ validator: this.validateNewPass, trigger: 'blur' }],
    checkPass: [{ validator: this.validateCheckPass, trigger: 'blur' }],
  }

  $store: any
  get userInfo() {
    return this.$store.getters.userInfo
  }

  private validateOldPass(rule: any, value: any, callback: any) {
    if (value === '') {
      callback(new Error(this.$t('base.oldPassword') as string))
    } else {
      if (this.submitForm.old_password !== '') {
        ;(this.$refs.ruleForm as Form).validateField('new_password')
      }
      callback()
    }
  }

  private validateNewPass(rule: any, value: string, callback: any) {
    if (value === '') {
      callback(new Error(this.$t('base.newPassword') as string))
    } else {
      if (this.submitForm.new_password !== '') {
        ;(this.$refs.ruleForm as Form).validateField('checkPass')
      }
      callback()
    }
  }

  private validateCheckPass(rule: any, value: any, callback: any) {
    if (value === '') {
      callback(new Error(this.$t('base.rePassword') as string))
    } else if (value !== this.submitForm.new_password) {
      callback(new Error(this.$t('base.diffPassword') as string))
    } else {
      callback()
    }
  }

  private changePassword() {
    ;(this.$refs.ruleForm as Form).validate(async (valid: any) => {
      if (valid) {
        const params = {
          username: this.userInfo.username,
          old_password: this.submitForm.old_password,
          new_password: this.submitForm.new_password,
        }
        this.loadingStart()
        const { status, msg } = await this.services.user.changePassword(params)
        this.loadingDone()
        if (status !== 201) {
          this.$message({
            type: 'error',
            message: msg,
            showClose: true,
          })
          return
        }
        await this.$router.push('/login')
      } else {
        console.log('error submit!!')
        return false
      }
    })
  }
}
